.event-absent {
    background-color: var(--danger1);
}

.event-absent:hover {
    background-color: var(--danger2);
}

.event-present {
    background-color: var(--success1);
}

.event-present:hover {
    background-color: var(--success2);
}

.event-excused {
    background-color: var(--warning1);
}

.event-excused:hover {
    background-color: var(--warning2);
}

.event-attendance-table-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    /* place-content: center; */
    height: 50px;
    cursor: pointer;
}

.event-attendance-table-row-text {
    text-align: center;
}
