/* GRADE SELECTOR ================ */
.select-attendance {
    margin: 15px auto;
    width: 90%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    background-color: var(--accent1);
    color: white;
  }
  
  .select-attendance-option:nth-child(1) {
    border-radius: 10px 0 0 10px;
  }
  
  .select-attendance-option:nth-last-child(1) {
    border-radius: 0 10px 10px 0;
  }

  .select-attendance-option {
    width: 100%;
    height: 40px;
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: var(--accent2);
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    border: none
  }
  
  .select-attendance-option:hover{
    background-color: var(--accent3);
  }
  
  #attendance-selected {
    background-color: var(--accent4);
    color: white;
  }