.carousel-container {
    background-color: #f1f1f1;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
}

.carousel-item {
    /* height: max(50vh, 500px); */
    min-width: 100%;
    width: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
    filter: brightness(50%);
}

@media screen  and (max-width: 768px) {
    .carousel-item {
        height: 300px
    }
}

@media screen and (min-width: 769px) {
    .carousel-item {
        height: 500px;
    }
}