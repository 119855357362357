.table-header {
    display: flex;
    justify-content: center;
}

.admin-filters {
    margin: 0 auto;
}

.user-owner-table,
.user-table {
    padding-bottom: 30vh;
}

@media screen and (max-width: 480px) {
    .admin-filters {
        width: 95%;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .admin-filters {
        width: 80%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .admin-filters {
        width: 60%;
    }
}

@media screen and (min-width: 1025px) {
    .user-owner-table {
        display: grid;
        place-content: center;
        grid-template-columns: repeat(8, 1fr);
        row-gap: 15px;
    } 

    .user-table {
        display: grid;
        place-content: center;
        grid-template-columns: repeat(6, 1fr);
        row-gap: 15px;
    }
    .admin-filters {
        width: 50%;
    }
}

/* GRADE SELECTOR ================ */
.select-grade {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    background-color: var(--accent1);
    color: white;
  }
  
  .select-grade-option:nth-child(1) {
    border-radius: 10px 0 0 10px;
  }
  
  .select-grade-option:nth-last-child(1) {
    border-radius: 0 10px 10px 0;
  }

  .select-grade-option {
    width: 100%;
    height: 40px;
    display: grid;
    place-content: center;
    cursor: pointer;
    /* border: 1px solid var(--accent2); */
  }
  
  .select-grade-option:hover{
    background-color: var(--accent2);
  }
  
  #grade-selected {
    background-color: var(--accent4);
  }