.gallery {
    height: auto;
    width: 100vw;
    background-color: var(--blue);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#gallery-video,
#gallery-photo {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.welcome-logo {
    background-color: transparent;
    z-index: 2;
    position: absolute;
    aspect-ratio: 1/1;
    height: 70%;
    width: 50%;
    object-fit: contain;
}