.edit-event-btn {
    height: 30px;
}

.select-event-type {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
}
  
.select-event-type-option {
    width: 100%;
    height: 40px;
    display: grid;
    place-content: center;
    cursor: pointer;
}
  
.select-event-type-option:nth-child(1) {
    border-radius: 10px 0 0 10px;
}
  
.select-event-type-option:nth-child(4) {
    border-radius: 0 10px 10px 0;
}
  
.select-event-type-option:hover{
    background-color: var(--accent2);
}
  
#event-type-selected {
    background-color: var(--accent3);
    color: white;
}

.submit-edit-event {
    height: 30px;
    margin-top: 5%;
}

.edit-event-date-time-selector {
    border: none;
    cursor: pointer;
}

#light .edit-event-date-time-selector {
    background-color: white;
    border-bottom: 1px solid gray;
    color: black;
}

#dark .edit-event-date-time-selector {
    background-color: var(--dark1);
    border-bottom: 1px solid white;
    color: white;
}

#light .edit-event-date-time-selector:hover {
    background-color: var(--light1);
}

#dark .edit-event-date-time-selector:hover {
    background-color: var(--dark2);
}