.front-page-media {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.front-page {
    width: 100vw;
    height: 100vh;
}

.video-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    /* border: 1px solid red; */
}

.front-page-welcome-text {
    margin-bottom: 0;
}

.front-page-welcome-desc {
    font-weight: bold;
}

.front-page-welcome-text,
.front-page-welcome-desc {
    color: white;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
    .front-page-welcome-text {
        font-size: 1.5rem;
    }
    .front-page-welcome-desc {
        font-size: 1rem;
    }
    .open-login {
        width: 200px;
        height: 5vh;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .front-page-welcome-text {
        font-size: 1.7rem;
    }
    .front-page-welcome-desc {
        font-size: 1.2rem;
    }
    .open-login {
        width: 40vw;
        height: 5vh;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .front-page-welcome-text {
        font-size: 2.5rem;
    }
    .front-page-welcome-desc {
        font-size: 1.5rem;
    }
    .open-login {
        width: 25vw;
        height: 5vh;
    }
}

@media screen and (min-width: 1025px) {
    .front-page-welcome-text {
        font-size: 3rem;
    }
    .front-page-welcome-desc {
        font-size: 1.5rem;
    }
    .open-login {
        width: 20vw;
        height: 5vh;
    }
}