.loading-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px; /* <--- GAME CHANGERS */
    top: 0px;  /* <--- GAME CHANGERS */
    display: grid;
    place-content: center;
    z-index: 1000;
}

#light .loading-screen{
    background-color: rgba(200, 200, 200, 0.7);
}

#dark .loading-screen {
    background-color: rgba(75, 75, 75, 0.7)
}

.spin-container {
    text-align: center;
    width: auto;
    height: 200px;
}
.spin {
    border: 3px solid #0D4B9F;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader2 {
    border: 3px solid #2a2a2a;
    width: 220px;
    height: 220px;
    position: relative;
    top: -216px;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader3 {
    border: 3px solid #005CDC;
    width: 240px;
    height: 240px;
    position: relative;
    top: -452px;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader4 {
    border: 3px solid #94B6E5;
    width: 260px;
    height: 260px;
    position: relative;
    top: -708px;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
} 
@keyframes rotate {
    0% {
      transform: rotateZ(-360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
}
@keyframes rotate2 {
    0% {
      transform: rotateZ(360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
}
#text {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    top: -857px;
}

#light #text {
    color: black;
}
#dark #text {
    color: white;
}