.sidebar {
    padding: 0 1%;
}

.add-calendar-event {
    height: 30px;
    margin-top: 20px;
}

.sidebar {
    height: 100%;
    width: 250px;
}

#light .sidebar {
    border: 1px solid var(--light2);
}

#dark .sidebar {
    border: 1px solid var(--dark2);
}

.event-filter {
    border-radius: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#light .event-filter:hover {
    background-color: var(--light1);
}

#dark .event-filter:hover {
    background-color: var(--dark2);
}

.color-filter-checkbox {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
    display: grid;
    place-content: center;
    color: white;
}

@media screen and (max-width: 768px) {
    .sidebar {
        display: none;
    }
}