.mission-txt {
    z-index: 2;
    margin: 0 50px;
    text-align: justify;
    position: absolute;
    background-color: transparent;
    background: none;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.mission-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 480px) {
    .mission-txt {
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .mission-txt {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .mission-txt {
        font-size: 1.7rem;
    }
}

@media screen and (min-width: 1025px) {
    .mission-txt {
        font-size: 2rem;
    }
}