.calendar-event {
    color: white;
    cursor: pointer;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-calendar-event {
    background-color: var(--danger1);
    height: 30px;
}

.delete-calendar-event:hover {
    background-color: var(--danger2);
}

.edit-delete-calendar-event {
    width: 50%;
    display: flex;
    justify-content: space-between;
}