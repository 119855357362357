.open-add-announcement {
    position: fixed;
    bottom: 7vh;
    left: 7vh;
    border: none;
    height: 30px;
    z-index: 10;
    height: 40px;
    border-radius: 50px;
    color: white;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.45) 5px 15px 20px 5px;
}

.plus {
    aspect-ratio: 1 / 1;
}


#add-announcement-description {
    width: 96%;
}

.submit-add-announcement {
    height: 30px;
}

.checkbox {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
    border: 2px solid gray;
    display: grid;
    place-content: center;
    color: black;
}

#light .checkbox {
    color: black;
}

#dark .checkbox {
    color: white;
}

.text-alert-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    width: fit-content;
    padding: 0 10px;
}

#light .text-alert-row:hover {
    background-color: var(--light1);
}
#dark .text-alert-row:hover {
    background-color: var(--dark3);
}