.sign-up-filter-container {
    margin: 0 auto;
    padding-top: 20px;
}

@media screen and (max-width: 480px) {
    .sign-up-filter-container,
    .sign-up-table {
        width: 95%;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .sign-up-filter-container,
    .sign-up-table {
        width: 80%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .sign-up-filter-container,
    .sign-up-table {
        width: 80%;
    }
}

@media screen and (min-width: 1025px) {
    .sign-up-filter-container,
    .sign-up-table {
        width: 80%;
    }
}

/* Event Labels ================ */
.sign-up-name-filter-label {
    color: white;
}

input[type="text"]:focus ~ .sign-up-name-filter-label, input:valid ~ .sign-up-name-filter-label {
    color: white;
}

.sign-up-name-filter-input {
    color: white;
    background-color: var(--accent1) !important;
}

.sign-up-name-filter-bar { 
    position:relative; 
    display:block; 
    width:100%;
}
.sign-up-name-filter-bar:before, 
.sign-up-name-filter-bar:after 	{
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:#ffffff;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
}
.sign-up-name-filter-bar:before {
    left:50%;
}
.sign-up-name-filter-bar:after {
    right:50%; 
}
  
/* active state */
.sign-up-name-filter-input:focus ~ .sign-up-name-filter-bar:before, .sign-up-name-filter-input:focus ~ .sign-up-name-filter-bar:after {
    width:50%;
}


/* GRADE SELECTOR ================ */
.sign-up-select-grade {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
  }
  
  .sign-up-select-grade-option {
    width: 100%;
    height: 40px;
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: var(--accent2);
  }
  
  .sign-up-select-grade-option:nth-child(1) {
    border-radius: 10px 0 0 10px;
  }
  
  .sign-up-select-grade-option:nth-last-child(1) {
    border-radius: 0 10px 10px 0;
  }
  
  .sign-up-select-grade-option:hover{
    background-color: var(--accent3);
  }
  
  #sign-up-grade-selected {
    background-color: var(--accent4);
    color: white;
  }