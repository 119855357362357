.day {
    width: 14%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

#light .day{
    border: 1px solid var(--light2);
}

#dark .day{
    border: 1px solid var(--dark2);
}

.day::-webkit-scrollbar {
    display: none;
}

.date-num,
.date-day {
    margin: 0;
    font-size: 15px;
}

#light .date-num,
#light .date-day {
    color: gray;
}

#dark .date-num,
#dark .date-day {
    color: white;
}

.date-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-calendar-event-by-day {
    height: 100%;
    cursor: pointer;
}

.current-day {
    padding: 5px;
    height: 20px;
    width: 20px;
    background-color: var(--accent1);
    color: white !important;
    border-radius: 50%;
    font-weight: 600;
    display: grid;
    place-content: center;
}