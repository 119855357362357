.user-dashboard {
    
    width: 100%;
    height: 60vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

#light .user-dashboard {
    background-color: var(--accent1);
}

#dark .user-dashboard {
    background-color: var(--accent5);
}

.info-box {
    border-radius: 10px;
    padding: 0 5%;
    height: 100%;
    width: 96%;
    overflow: scroll;
}

#light .info-box {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
}

#dark .info-box {
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 15px;
}

#light .info-box {
    background-color: white;
}

#dark .info-box {
    background-color: var(--dark3);
}

.info-box::-webkit-scrollbar {
    display: none;
}

.info-title,
.dashboard-info {
    margin-left: 15px;
    margin-right: 15px;
}

.info-title {
    text-align: center;
}

.individual-event-link {
    cursor: pointer;
    padding: 0 1rem;
    height: 2rem;
    display: flex;
    align-items: center;
}

.user-dashboard-sign-up-container {
    display: flex;
    align-items: center;
}

.user-dashboard-sign-up,
.user-dashboard-design-up {
    display: inline-block;
    white-space: nowrap;
    padding: 0 10px;
    min-width: fit-content;
}

.user-dashboard-sign-up {
    background-color: var(--success1);
}

.user-dashboard-design-up {
    background-color: var(--danger1);
}

.user-dashboard-sign-up:hover,
.user-dashboard-sign-up:focus {
    background-color: var(--success2);
}

.user-dashboard-design-up:hover,
.user-dashboard-design-up:focus {
    background-color: var(--danger2);
}

.user-dashboard-sign-up,
.user-dashboard-design-up {
    height: 30px;
    margin-left: 5%;
}

@media screen and (max-width: 768px) {
    .user-dashboard {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: auto;
    }

    .info-box {
        width: 90%;
        min-height: 20vh;
        max-height: 30vh;
    }
}


@media screen and (min-width: 769px) {
    .user-dashboard {
        display: grid;
        grid-template-columns: 40% 40%;
        grid-template-rows: 1fr 1fr;
        place-content: center;
        column-gap: 5%;
        row-gap: 7%;
    }
    
    .upcoming-events {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    
    .attendance {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
    }
    
    .sign-ups {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
    }
}