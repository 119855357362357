ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar {
    height: 5vh;
    width: 100vw;
    z-index: 10;
    /* background-color: rgb(0, 0, 75); */
    background-color: var(--accent2);
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
}

.nav-redirect {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-link {
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    width: 100%;
    margin: auto;
}

.nav-link:hover {
    /* background-color: #030f1b; */
    background-color: var(--accent4);
}

.nav-link a {
    color: white;
    width: 100%;
    text-align: center;
}

.current-page {
    /* background-color: rgb(0, 0, 40); */
    background-color: var(--accent4);
}

.user-icon-container {
    width: auto;
}

/* Dropdown Button */
.nav-dropbtn {
    background-color: var(--accent3);
    color: white;
    font-size: 24px;
    border: none;
    aspect-ratio: 1/1;
    height: 5vh;
    align-items: center;
    width: auto;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
}

.nav-dropbtn:hover {
    background-color: var(--accent5);
}

/* The container <div> - needed to position the dropdown content */
.nav-dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.nav-dropdown-content {
    display: none;
    position: absolute;
    max-width: 300px;
    z-index: 1;
}

#light .nav-dropdown-content {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
#dark .nav-dropdown-content {
    box-shadow: 0px 8px 16px 0px rgba(255,255,255,0.1);
}

#light .nav-dropdown-content {
    background-color: #f1f1f1;
}
#dark .nav-dropdown-content {
    background-color: var(--dark2);
}

/* Links inside the dropdown */
.nav-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

#light .nav-dropdown-content a {
    color: black;
}
#dark .nav-dropdown-content a {
    color: white;
}

/* Show the dropdown menu on hover */
.nav-dropdown:hover .nav-dropdown-content {display: block;}

.nav-dropdown-item {
    cursor: pointer;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

#light .nav-dropdown-item {
    color: black;
}
#dark .nav-dropdown-item {
    color: white
}

#light .nav-dropdown-item:hover {
    background-color: var(--light1);
}
#dark .nav-dropdown-item:hover {
    background-color: var(--dark3);
}

@media screen and (max-width: 480px) {
    .nav-dropdown-content {
        width: 50vw;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .nav-dropdown-content {
        width: 40vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .nav-dropdown-content {
        width: 30vw;
    }
}

@media screen and (min-width: 1025px) {
    .nav-dropdown-content {
        width: 20vw;
    }
}