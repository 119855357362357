.small-calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.small-calendar-weekdays {
    padding: 5px;
}

.small-calendar-week {
    display: flex;
}

.small-calendar-button {
    border: none;
    border-radius: 50%;
    height: 25px;
    aspect-ratio: 1/1;
    cursor: pointer;
}

#light .small-calendar-button {
    background-color: white;
    color: black;
}
#dark .small-calendar-button {
    background-color: var(--dark1);
    color: white;
}

#light .small-calendar-current-day,
#dark .small-calendar-current-day {
    background-color: var(--accent1);
    color: white;
}

.small-calendar-selected-day {
    font-weight: 600;
}

#light .small-calendar-selected-day {
    background-color: var(--light2);
    color: var(--accent1);
}

#dark .small-calendar-selected-day {
    background-color: var(--dark3);
    color: var(--accent1);
}

.change-small-month {
    display: flex;
    height: 100%;
}

.change-small-month-btn {
    border: none;
    background-color: transparent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
}

#light .change-small-month-btn {
    background-color: white;
    color: black;
}

#dark .change-small-month-btn {
    background-color: var(--dark1);
    color: white;
}

#light .change-small-month-btn:hover {
    background-color: var(--light1);
}

#dark .change-small-month-btn:hover {
    background-color: var(--dark2);
}