.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}

.toggle-sidebar {
    cursor: pointer;
    font-size: 2rem;
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

#light .toggle-sidebar {
    background-color: white;
    color: black;
}

#dark .toggle-sidebar {
    background-color: var(--dark1);
    color: white;
}

#light .toggle-sidebar:hover {
    background-color: var(--light1);
}

#dark .toggle-sidebar:hover {
    background-color: var(--dark2);
}

.header-date-change {
    display: flex;
}

.select-today {
    border-radius: 10px;
    height: 40px;
    padding: 0 1rem;
    cursor: pointer;
    font-weight: bold;
    margin: 0 20px;
}

#light .select-today {
    background-color: white;
    border: 1px solid rgb(99, 99, 99);
    color: black;
}

#dark .select-today {
    background-color: var(--dark1);
    border: 1px solid white;
    color: white;
}

.select-today:hover {
    background-color: rgb(231, 231, 231);
}

#light .select-today:hover {
    background-color: var(--white1);
}

#dark .select-today:hover {
    background-color: var(--dark2);
}

.change-month {
    border: none;
    background-color: transparent;
    height: 40px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#light .change-month {
    color: black;
}

#dark .change-month {
    color: white;
}

#light .change-month:hover {
    background-color: var(--light1);
}

#dark .change-month:hover {
    background-color: var(--dark2);
}

.header-month-selected {
    position: absolute;
    left: 60%;
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    .calendar-title {
        display: none;
    }
    .calendar-header {
        width: 40vw;
    }
}

@media screen and (min-width: 769px) {
    .calendar-header {
        width: 40vw;
    }
}   