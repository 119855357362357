.event-type-container {
    display: flex;
}

.event-type-selector {
    width: 25px;
    height: 25px;
    margin: 4px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    color: white;
}

.exec-only-checkbox {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
    display: grid;
    place-content: center;
}

#light .exec-only-checkbox {
    border: 2px solid gray;
    color: black;
}

#dark .exec-only-checkbox {
    border: 2px solid white;
    color: white;
}

#light .exec-only:hover {
    background-color: var(--light1);
}

#dark .exec-only:hover {
    background-color: var(--dark2);
}

.exec-only {
    padding: 10px;
    width: 150px;
    display: flex;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
}