.open-add-bio {
    position: fixed;
    bottom: 7vh;
    left: 7vh;
    border: none;
    height: 30px;
    z-index: 10;
    height: 40px;
    border-radius: 50px;
    background-color: var(--accent1);
    color: white;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.45) 5px 15px 20px 5px;
}

.plus {
    aspect-ratio: 1 / 1;
}

.file-input {
    display: none !important;
}

.upload-photo {
    height: 30px;
}

.submit-add-bio {
    height: 30px;
}

#profile-bio {
    width: 96%;
}