.announcement-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 100px;
    gap: 25px;
}

#light .announcement-list {
    background-color: white;
}

#dark .announcement-list {
    background-color: var(--dark1);
}