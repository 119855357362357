/* Dropdown Button */
.dropbtn {
    background-color: var(--accent3);
    color: white;
    font-size: 16px;
    border: none;
    padding-top: auto;
    padding-bottom: auto;
    padding-left: 20px;
    padding-right: 20px;
    height: 5vh;
    align-items: center;
    width: auto;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
}

.dropbtn:hover {
    background-color: var(--accent5);
}

.user-email {
    padding-right: 15px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    max-width: 300px;
    z-index: 1;
}

#light .dropdown-content {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
#dark .dropdown-content {
    box-shadow: 0px 8px 16px 0px rgba(255,255,255,0.1);
}

#light .dropdown-content {
    background-color: #f1f1f1;
}
#dark .dropdown-content {
    background-color: var(--dark2);
}

/* Links inside the dropdown */
.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

#light .dropdown-content a {
    color: black;
}
#dark .dropdown-content a {
    color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.dropdown-item {
    cursor: pointer;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

#light .dropdown-item {
    color: black;
}
#dark .dropdown-item {
    color: white;
}

#light .dropdown-item:hover {
    background-color: var(--light1);
}
#dark .dropdown-item:hover {
    background-color: var(--dark3);
}

@media screen and (max-width: 480px) {
    .dropdown-content {
        width: 50vw;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .dropdown-content {
        width: 40vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .dropdown-content {
        width: 30vw;
    }
}

@media screen and (min-width: 1025px) {
    .dropdown-content {
        width: 20vw;
    }
}