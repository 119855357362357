.my-attendance-table {
    margin: 0 auto;
}

.my-attendance-table-heading {
    display: grid;
    grid-template-columns: 1fr 1fr 80px;
    color: white;
    background-color: var(--accent5);
}

.my-attendance-table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 80px;
    color: white;
}

.my-attendance-table-row:nth-child(odd) {
    background-color: var(--accent1);
}

.my-attendance-table-row:nth-child(even) {
    background: var(--accent2);
}

.my-attendance-table-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-table-present-info {
    aspect-ratio: 1/1;
}

.my-attendance-present {
    background-color: var(--success1);
}

.my-attendance-absent {
    background-color: var(--danger1);
}

.my-attendance-excused {
    background-color: var(--warning1);
}

.my-attendance-not-present {
    background-color: var(--danger1);
}

@media screen and (max-width: 480px) {
    .my-attendance-table {
        width: 90%;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .my-attendance-table {
        width: 80%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .my-attendance-table {
        width: 70%;
    }
}

@media screen and (min-width: 1025px) {
    .my-attendance-table {
        width: 60%;
    }
}