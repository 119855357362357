.sign-up-info-container {
    width: 90%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--accent2);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.show-hide-sign-up-info {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.sign-up-separator {
    background-color: gray;
}