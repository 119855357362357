.event-date-menu {
    margin-bottom: 30px;
}

.add-event-time-info {
    display: flex;
    align-items: center;
}

.event-time-info {
    border-radius: 5px;
    margin: 5px;
    padding: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#light .event-time-info:hover {
    background-color: var(--light1);
}

#dark .event-time-info:hover {
    background-color: var(--dark2);
}

.time-selector {
    height: 100%;
    width: 100%;
    overflow: scroll;
    z-index: 20;
}

#light .time-selector {
    background-color: white;
}

#dark .time-selector {
    background-color: var(--dark1);
}

.individual-time {
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
}

#light .individual-time:hover {
    background-color: var(--light1);
}

#dark .individual-time:hover {
    background-color: var(--dark2);
}

.submission-error {
    color: red;
}

.all-day-checkbox {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
    display: grid;
    place-content: center;
}

#light .all-day-checkbox {
    border: 2px solid gray;
    color: black;
}

#dark .all-day-checkbox {
    border: 2px solid white;
    color: white;
}

.all-day {
    padding: 5px;
    width: 100px;
}

@media screen and (max-width: 480px) {
    .start-end-time-selector {
        width: 40vw;
        height: 50vh;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .start-end-time-selector {
        width: 30vw;
        height: 50vh;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .start-end-time-selector {
        width: 25vw;
        height: 50vh;
    }
}

@media screen and (min-width: 1025px) {
    .start-end-time-selector {
        width: 20vw;
        height: 50vh;
    }
}

