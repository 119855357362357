.bio-list {
    margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
    .bio-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 769px) {
    .bio-list {
        display: grid;
        place-content: center;
        gap: 2%;
        padding: 2%;
    }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .bio-list {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 50px;
    }
}

@media screen and (min-width: 1025px) {
    .bio-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

