.sign-up-table {
    margin: 0 auto;
    margin-bottom: 50px;
    display: grid;
    
}

.sign-up-table-head {
    background-color: var(--accent5);
    display: grid;
    place-content: center;
    height: 100%;
}

.sign-up-table-body {
    display: flex;
    flex-direction: column;
}

.sign-up-row {
    display: grid;
    place-content: center;
    height: 100%;
}

.sign-up-row:nth-child(odd) {
    background-color: var(--accent2);
}

.sign-up-row:nth-child(even) {
    background-color: var(--accent3);
}