:root {
  --light1: #ebeaeb;
  --light2: #d7d7d7;
  
  --dark1: #28273b;
  --dark2: #222133;
  --dark3: #13131e;

  --accent1: #1c72e7;
  --accent2: #2a50a2;
  --accent3: #1f3c7b;
  --accent4: #1a3164;
  --accent5: #00004b;

  --danger1: #c80000;
  --danger2: #aa0000;

  --success1: #00a000;
  --success2: #008200;

  --warning1: #ddbc00;
  --warning2: #c3a600;
}

.App {
  min-height: 100vh;
  height: max-content;
}

#root {
  min-height: 100vh;
  height: max-content;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  min-width: 320px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



#light {
  background-color: white;
  color: black;
}

#dark {
  background-color: var(--dark1);
  color: white;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
a:visited,
a:focus {
  text-decoration: none;
  color: inherit;
}

.btn {
  align-items: center;
  background-color: var(--accent1);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.btn:hover,
.btn:focus { 
  background-color: var(--accent2);
  color: #ffffff;
}

.btn:active {
  background: var(--accent5);
  color: rgb(255, 255, 255, .7);
}

.btn:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}

/* forms */
/* form starting stylings ------------------------------- */
.input-group 			  { 
  position:relative; 
  margin-bottom:45px; 
}

input[type="text"],
input[type="password"],
input[type="email"] {
  font-size:18px;
  padding:10px 10px 10px 5px;
  width: 100%;
  display:block;
  border:none;
  border-bottom:1px solid #757575;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus { 
  outline:none; 
}

#light input[type="text"],
#dark input[type="password"],
#dark input[type="email"] { 
  background-color: white;
  color: black;
}

#dark input[type="text"],
#dark input[type="password"],
#dark input[type="email"] { 
  background-color: var(--dark1);
  color: white;
}

/* LABEL ======================================= */
label 				 {
  color:#999; 
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
input[type="text"]:focus ~ label, input:valid ~ label,
input[type="password"]:focus ~ label, input:valid ~ label,
input[type="email"]:focus ~ label, input:valid ~ label {
  top:-20px;
  font-size:14px;
  color:#5264AE;
}

/* BOTTOM BARS ================================= */
.bar 	{ 
  position:relative; 
  display:block; 
  width:100%;
}
.bar:before, 
.bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:#5264AE; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}

/* active state */
input[type="text"]:focus ~ .bar:before, input:focus ~ .bar:after,
input[type="password"]:focus ~ .bar:before, input:focus ~ .bar:after,
input[type="email"]:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

#light .txtarea {
  background-color: white;
  color: black;
}

#dark .txtarea {
  background-color: var(--dark1);
  color: white;
}

.txtarea {
  resize: none;
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  border:1px solid #757575;
  height: 200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.txtarea::-webkit-scrollbar {
  display: none;
}

.txtarea:focus { 
  outline:none; 
}

/* active state */
.txtarea:focus ~ label, 
.txtarea:valid ~ label {
  top:-20px;
  font-size:14px;
  color:#5264AE;
}

/* active state */
.txtarea:focus ~ .bar:before, .txtarea:focus ~ .bar:after {
  width:50%;
}

/* active state */
.txtarea:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}

.separating-line {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
}