.user-row-info-container {
    width: 90%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#light .user-row-info-container {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#dark .user-row-info-container {
    background-color: var(--dark3);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 5px 15px;
}

.show-hide-user-row-info {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

#light .show-hide-user-row-info:hover {
    background-color: var(--light1);
}

#dark .show-hide-user-row-info:hover {
    background-color: var(--dark3);
}

.user-row-info-owner-content {
    display: grid;
    width: 100%;
    justify-content: space-between;
    place-content: center;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.user-row-info-content {
    display: grid;
    width: 100%;
    justify-content: space-between;
    place-content: center;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.user-row-info {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 480px) {
    .user-row-info-title {
        font-size: 0.8rem;
    }
}

.admin-separator {
    background-color: gray;
}