.open-add-event {
    position: fixed;
    bottom: 7vh;
    left: 7vh;
    border: none;
    height: 30px;
    z-index: 10;
    height: 40px;
    border-radius: 50px;
    background-color: var(--accent1);
    color: white;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.45) 5px 15px 20px 5px;
}

.plus {
    aspect-ratio: 1 / 1;
}


#add-event-description {
    width: 96%;
}

.submit-add-event {
    height: 30px;
    margin-top: 5%;
}

.select-event-type {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
}
  
.select-event-type-option {
    width: 100%;
    height: 40px;
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: var(--accent1);
}
  
.select-event-type-option:nth-child(1) {
    border-radius: 10px 0 0 10px;
}
  
.select-event-type-option:nth-child(4) {
    border-radius: 0 10px 10px 0;
}
  
.select-event-type-option:hover{
    background-color: var(--accent2);
}
  
#event-type-selected {
    background-color: var(--accent3);
    color: white;
}

.event-text-alert-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    width: fit-content;
    padding: 0 10px;
    margin-top: 20px;
}

#light .event-text-alert-row:hover {
    background-color: var(--light1);
}

#dark .event-text-alert-row:hover {
    background-color: var(--dark2);
}

.add-event-date-time-selector {
    border: none;
    cursor: pointer;
}

#light .add-event-date-time-selector {
    background-color: white;
    border-bottom: 1px solid gray;
    color: black;
}

#dark .add-event-date-time-selector {
    background-color: var(--dark1);
    border-bottom: 1px solid white;
    color: white;
}

#light .add-event-date-time-selector:hover {
    background-color: var(--light1);
}

#dark .add-event-date-time-selector:hover {
    background-color: var(--dark2);
}
