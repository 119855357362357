.modal-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200, 0.7);
    position: fixed;
    left: 0px; /* <--- GAME CHANGERS */
    top: 0px;  /* <--- GAME CHANGERS */
    display: grid;
    place-content: center;
    z-index: 100;
}

#light .modal-container {
    background-color: white;
}

#dark .modal-container {
    background-color: var(--dark1);
}

.modal-container {
    border-radius: 12px;
    /* background-color: white; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modal-body {
    width: 95%;
    height: 100%;
    overflow: scroll;
}

.modal-body::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 768px) {
    .modal-container {
        width: 80vw;
        height: 60vh;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .modal-container {
        height: 50vh;
        width: 50vw;
    }
}

@media screen and (min-width: 1025px) {
    .modal-container {
        height: 60vh;
        width: 40vw;
    }
}

.close-btn {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

#light .close-btn {
    color: black;
}

#dark .close-btn {
    color: white;
}
