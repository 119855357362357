.bio {
    width: 90%;
    height: auto;
    padding: 5%;
}


.bio-photo {
    width: 50%;
    object-fit: cover;
    aspect-ratio: 3 / 4;
}

.bio-name,
.bio-description {
    text-align: center;
}

.bio-photo-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .bio {
        width: 80%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
}

.edit-bio-btn,
.delete-bio-btn {
    height: 30px;
}

.delete-bio-btn {
    background-color: var(--danger1);
}

.delete-bio-btn:hover,
.delete-bio-btn:focus {
    background-color: var(--danger2);
}

.bio-btn-group {
    display: flex;
    justify-content:space-evenly;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .bio-btn-group {
        width: 80%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .bio-btn-group {
        width: 60%;
    }
}

@media screen and (min-width: 1025px) {
    .bio-btn-group {
        width: 70%;
    }
}