.delete-modal {
    display: grid;
    place-content: center;
    height: 90%;
    color: black;
}

.modal-btn-row {
    display: flex;
    justify-content: center;
}

.delete-btn,
.cancel-delete-btn {
    height: 30px;
    
}

.delete-btn {
    background-color: var(--danger1);
    margin-right: 10px;
}

.delete-btn:hover,
.delete-btn:focus {
    background-color: var(--danger2);
}

#light .delete-txt {
    color: black;
}

#dark .delete-txt {
    color: white;
}