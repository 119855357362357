.delete-user {
    width: 30%;
    height: 30px;
}

.delete-allowed {
    background-color: var(--danger1);
    color: white;
}

.delete-allowed:hover {
    background-color: var(--danger2);
}

.delete-not-allowed {
    background-color: rgb(206, 206, 206);
    border: 1px solid gray;
    color: black;
}

.delete-not-allowed:hover {
    background-color: rgb(206, 206, 206);
    border: 1px solid gray;
    color: black;
}