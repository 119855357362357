.link-to-settings {
    height: 30px;
    display: block;
    margin: 0 auto;
}

.link-container {
    padding: 20px;
}

.link-phone-title {
    text-align: center;
    color: white;
}