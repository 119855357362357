.settings-body {
    margin: 0 25px;
    display: grid;
    gap: 20px;
}

@media screen and (max-width: 480px) {
    .settings-body {
        grid-template-columns: repeat(1, 1fr)
    }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .settings-body {
        grid-template-columns: repeat(2, 1fr)
    }
}

@media screen and (min-width: 769px) {
    .settings-body {
        grid-template-columns: repeat(3, 1fr)
    }
}
